import { Link } from "react-router-dom";
import BreadCrumbs from '../breadcrumbs'
import {useDispatch, useSelector} from 'react-redux'
import { changeLocationAction } from '../../redux/themeDucks';


const Lotes = () =>{
    const {data} = useSelector(store => store.developments.developments)
    const settings = useSelector((store) => store.settings);

    const dispatch = useDispatch();

    function shortLocation (full_location){
        var split = full_location.split("|")
        return split[3] + '| ' + split[1] 
    }

    return data ? (
        <section className={"lotes "}>
            <div className="container-fluid">
                    <BreadCrumbs props={[
                        {name:'Home',route:settings.base_url,location:''},
                        {name:'Emprendimientos',route:settings.base_url + 'emprendimientos',location:'emprendimientos',active:true},
                    ]}/>
                <span className="span_emprendimientos">NUESTROS EMPRENDIMIENTOS</span>
                <h2>
                    Conocé nuestras opciones <br className="d-lg-inline-block d-none" />
                    para tu próxima inversión.
                </h2>
                <div className="row justify-content-between">
                    {data.objects.map((lote,index) =>  (
                        <div className="col-lg-6 mb-3 ">
                            <div className={'grid_item'} alt="" >
                            <img src={(lote.photos).find(element => element.is_front_cover === true).original} alt="" />
                            <div className="hover d-flex flex-column justify-content-center  text-center  align-items-center text-white">
                                
                                <h5>{lote.fake_address}</h5>
                                <p>{shortLocation(lote.location.full_location)}</p>
                                
                                <Link to={settings.base_url + 'emprendimientos/' + lote.id}  onClick={() => dispatch(changeLocationAction('loteo'))} ><i className="icon-arrow"></i></Link>
                            </div>
                        </div> 
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )   
    : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )

}

export default Lotes