import React , {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'


// -- Componentes -- //

import Main from '../components/tasaciones/main'


function Tasaciones(){

    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = settings.seo.title
      }, []);

    return(
        <div className="tasaciones">
            <Main />
        </div>
    )
}

export default Tasaciones
