import {useDispatch, useSelector} from 'react-redux'
import { Link } from 'react-router-dom'
import { changeLocationAction } from '../../redux/themeDucks';
import {shortDirection} from '../../functions/functions_property'


const Lotes = () =>{
    // const {data} = useSelector(store => store.developments.development)
    const {data} = useSelector(store => store.developments.units_development)
    const settings = useSelector(store => store.settings)

    const dispatch = useDispatch();
    return data ? (
        <section className="lotes">
            <div className="container-fluid">
                <h2>Unidades disponibles.</h2>
                <div className="row pt-lg-5 mt-lg-5 mt-4">
                    <div className="col-lg-5 col-3">
                        <h5>Lotes</h5>
                    </div>
                    <div className="col-lg-6 col-7">
                        <div className="row">
                            <div className="col-5">
                                <h5 className="text-lg-center">Sup. Total</h5>
                            </div>
                            <div className="col-7">
                                <h5 className="text-lg-center">Valor</h5>
                            </div>
                        </div>
                    </div>
                </div>
                {data.objects.map((item,index) => (
                    <div className="row py-lg-4 py-3 mt-lg-3 mt-2 underlined">
                        <div className="col-lg-5 col-3">
                            <p>{item.publication_title}</p>
                        </div>
                        <div className="col-lg-6 col-7">
                            <div className="row">
                                <div className="col-5">
                                    <p className="text-lg-center">{item.surface} {item.surface_measurement}</p>
                                </div>
                                <div className="col-7">
                                    <p className="text-lg-center">{item.operations[0].prices[0].currency} {new Intl.NumberFormat("de-DE").format(item.operations[0].prices[0].price)} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 col-lg-1 d-flex justify-content-end"><Link onClick={() => dispatch(changeLocationAction("propiedad"))} to={settings.base_url + "propiedad/" + shortDirection(item.operations[0].operation_type , item.type.name, item.location.name , item.id , item.fake_address)} className="btn d-flex justify-content-center align-items-center text-center p-0"><i className="icon-arrow"></i></Link></div>
                    </div>
                ))}

            </div>
        </section>
    ): (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Lotes