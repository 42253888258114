import React , {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getDevelopmentsAction } from '../redux/developmentsDucks';
import { getListFilters } from '../redux/propertiesDucks';

// -- Componentes -- //
import Main from '../components/inicio/main'
import Lotes from '../components/inicio/lotes'
import Tasaciones from '../components/inicio/tasaciones'
import About from '../components/inicio/about'


function Inicio(){

    const dispatch = useDispatch();
    
    useEffect(() => {
        const getDevs = () => {
            dispatch(getDevelopmentsAction())
            dispatch(getListFilters())
            window.scrollTo(0, 0);
        }
        getDevs()
    }, [])

    return(
        <div className="Inicio">
            <Main />
            {/* <Lotes /> */}
            <Tasaciones />
            <About />
        </div>
    )
}

export default Inicio
