
import Card from './../property-card';
import { useSelector,useDispatch } from "react-redux";
import {getAdd_PropertiesAction} from "../../redux/propertiesDucks"
import Paginate from './paginate';


const Results = () =>{
    const dispatch = useDispatch()
    const listed = useSelector((store) => store.properties.listed);
    const properties = useSelector((store) => store.properties.properties);
    const theme = useSelector((store) => store.theme);
    const offset = useSelector((store) => store.properties.offset);
  
    const checkStatusButton = () =>{
      if(properties.data){
        if(properties.data.objects.length > 0 && properties.data.objects.length < properties.data.meta.total_count){
          return true
        }
        return false
      }
    }
    return(
        <div className="col-lg-8 px-lg-0">
        <div className="results">
          { properties.data ?
              properties.data.meta.total_count === 0 ?
                <div className="col-12">
                <span className="not-results-message">En estos momentos no hay resultados para tu búsqueda.</span>
                </div>
                : ''
            :''}
          <div className="row no-gutters justify-content-start">
            {properties.data ? properties.data.objects.map((property, index) =>
                (
                  <div className={(!listed ? "col-lg-6" : "col-lg-12") + " px-lg-4 mb-4 mb-lg-5"}>
                  <Card property={property} />
                </div>
                )
            ):
            <div className="loading-section"><div className="lds-dual-ring"></div> </div>}
            {/* <div className={"col-12 py-5 mb-5 text-center " + (checkStatusButton() ? 'd-block' : "d-none" )}>
              <button onClick={() => dispatch(getAdd_PropertiesAction())} className="w-auto border-none mx-auto my-3 btn button_more_propiedades">
               <div className="open_more_propiedades"><span></span><span></span></div><span className="my-auto text_button_more_propiedades ">MÁS PROPIEDADES</span></button>
            </div> */}
            <div className={"col-12 py-5 mb-5 text-center "}>
              <Paginate />
            </div>

          </div>
        </div>
      </div>
    )
}

export default Results