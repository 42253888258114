import Filter from './filter'
import Properties from './result-properties'

const Grid = () =>{
    return(
        <div className="container-fluid px-lg-5">
            <div className="row">
                <Filter></Filter>
                <Properties></Properties>
            </div>
        </div>
    )
}

export default Grid