import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import ReCAPTCHA from "react-google-recaptcha";
import { updateFields,formTokko } from '../../redux/contactDucks'

const Contact = () => {
    const dispatch = useDispatch();
    const { data } = useSelector((store) => store.developments.development);
    const settings = useSelector(store => store.settings)
    const [disabled,setDisabled] = useState(true);
    const [text,setText] = useState('')
    const [datos, setDatos] = useState({
        nombre:'',
        apellido:'',
        email:'',
        text:'',
    })
    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name] : event.target.value, 
        })
    }
    const sendForm = (event) => {
        event.preventDefault()
        dispatch(formTokko('Emprendimiento',data.fake_address,data.id))
        document.getElementById("formContact").reset()
        setText("¡Muchas gracias! en breve nos estaremos contactando con ud.")
  }
    useEffect(() => {
        const update = () => {
            dispatch(updateFields(datos))
        }
        update()
    }, [datos])
    return(
        <section className="contact-main">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6">
                        <h2>
                            Consultanos por 
                            este emprendimiento.
                        </h2>
                    </div>
                    {/* <div className="col-lg-6">
                        <div className="content-contact">
                            <span className="d-block">MA: <a href={"mailto:" + settings.contact.email}> {settings.contact.mail}</a></span>
                            <span className="d-block">TE: <a href="#"> {settings.contact.phone[0] + ' / ' + settings.contact.phone[1]}</a></span>
                        </div>
                    </div> */}
                </div>
                <form onSubmit={sendForm} id="formContact" action="">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column align-items-start order-1 order-lg-1">
                            <input type="text" name="nombre" placeholder="Nombre" onChange={handleInputChange} id="" />
                            <input type="text" className="mt-lg-5 mb-0" name="cellphone" onChange={handleInputChange} placeholder="Teléfono" id="" />
                        </div>
                        <div className="col-lg-6 order-2 order-lg-3">
                            <input type="text" name="email" className="mt-lg-5" onChange={handleInputChange} placeholder="*E-Mail"  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required id="" />
                        </div>
                        <div className="col-lg-6 order-3 order-lg-2">
                            <textarea name="text" placeholder="Mensaje" onChange={handleInputChange} id="" cols="30"></textarea>
                        </div>
                        <div className="col-lg-6 order-lg-4 order-4 mt-lg-5 ">
                            <ReCAPTCHA sitekey={settings.keys.captcha} onChange={() => setDisabled(false)} />
                            <div className="d-block">
                                <span id="text-form" className={(text == "" ? 'd-none' : 'd-block') + ' mt-4 mb-0'}>{text}</span>
                            </div>  
                            <button type="submit" href="#" className="btn btn-effect transparent d-inline-block btn_contact_emprendimiento" disabled={disabled}  ><p>ENVIAR</p></button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Contact