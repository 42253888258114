import { Link , useLocation } from "react-router-dom";
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import Header from './header'


function Footer(){

    const [offset, setOffset] = useState(0);
    const [coordenadas,setCoordenadas] = useState(4000)
    const settings = useSelector(store => store.settings)
    useEffect(() => {
        window.onscroll = () => {
            setOffset(window.pageYOffset)
            setCoordenadas(($("footer").offset()).top)
        }
      }, []);
    function scrollTop (){
        $('html, body').scrollTop(0)
        return true;
    }

    const getYear = () => {
        const day = new Date()
        return day.getFullYear()
    }
    const currentYear = new Date().getFullYear();

    return(
        <div>
            <footer className="text-center text-lg-start">
                    <div class="fixed-bar position-fixed">
                        {/* <a href={'https://web.whatsapp.com/send?text=¡Hola!&amp;phone=+5493644571181'} target="_blank" class="icon-whatsapp contact-btn d-none d-lg-flex"></a> */}
                        <a href={'https://api.whatsapp.com/send?phone=+'  + settings.contact.whatsapp.link} target="_blank" class="icon-whatsapp contact-btn d-flex"></a>
                        <div onClick={() => scrollTop()} className={'btn d-none d-lg-flex justify-content-center align-items-center go-top animate__animated animate__zoomIn ' + (offset > (coordenadas) - 2500 && offset > 300 ? 'active' : '')}><span className="icon-arrow"></span></div>
                    </div>
                    <div className="scroll-bar position-fixed d-block d-lg-none">
                        <div onClick={() => scrollTop()} className={'btn d-flex justify-content-center align-items-center go-top animate__animated animate__zoomIn ' + (offset > (coordenadas) - 2500 && offset > 300 ? 'active' : '')}><span className="icon-arrow"></span></div>
                    </div>
                <div className="container-fluid">
                    <div className="row footer-nav">
                        <Header/>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-lg-3">
                            <img src={settings.base_url +"assets/images/black-giesso-logo.svg"} alt="Giesso Propiedades" className="logo"/>
                            <div className="mt-5 enrollment-wrap">
                                <span className="text-uppercase enrollment-text">Matrícula</span>
                                <span className="enrollment-number">1043</span>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-5">
                            <div className="content">
                                <h5>OFICINA</h5>
                                <p>
                                    {settings.location.address}<br />
                                    {settings.location.region}<br />
                                    CP: {settings.location.cp}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-5">
                            <div className="content">
                                <h5>CONTACTO</h5>
                                <p>
                                    TE: {settings.contact.phone[0] + ' / ' + settings.contact.phone[1]}<br />
                                    WA: {settings.contact.whatsapp.visual}<br />
                                    MA: <a href={'mailto:' + settings.contact.mail}>{settings.contact.mail} </a>
                                </p>
                            </div>
                        </div>
                        <div className="d-none btn-filters"></div>
                        <div className="col-lg-3 mt-5">
                            <div className="content">
                                    <h5>SEGUINOS EN</h5>
                                    <span className="d-block">Instagram <a target="_blank" href={settings.social.instagram}>@giessoprop</a></span>
                                    <span className="d-block">Facebook <a target="_blank" href={settings.social.facebook}>@giessoprop</a></span>
                                    {/* <span className="d-block">Twitter <a target="_blank" href={settings.social.twitter}>@fanellitrabalon</a></span> */}
                            </div>
                        </div>
                        <div className="col-12 d-lg-flex justify-content-center mt-5">
                            <div className="copy text-center">
                            <p> Design by {" "}<a className="mediahaus-link"  target="_blank" href="https://mediahaus.com.ar/">{" "}MediaHaus{" "} </a>{" "} - Powered By <a className="mediacore-link"  href="http://mediacore.com.ar/app">MediaCore{" "}</a>
                            <br /> <span className="reservated">Todos los derechos reservados &reg; {currentYear} </span></p>
                            </div>
                            {/* <img className="qr d-none d-lg-block" src={settings.base_url +"assets/images/qr.png"} alt="" /> */}
                        </div>
                    </div>
                </div>
            </footer>
        </div>
       
    )
}

export default Footer