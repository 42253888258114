import {useEffect,useState} from 'react'
import React from 'react';
import ContentLoader from 'react-content-loader'
import { getPropertiesAction,changeOffsetPropertiesAction } from '../../redux/propertiesDucks';
import { connect , useDispatch, useSelector } from 'react-redux'
// import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from 'react-router-dom';
// import { useLocation } from '@reach/router'; comentado ya que en todo el proyecto se lo importa desde react-router-dom
import $ from 'jquery'

const PaginatedItems = () => {
  
  const dispatch = useDispatch();
  const itemsPerPage = 100;
  const [currentPage, setCurrentPage] = useState(0);
  const [items, setItems] = useState([0,1,2]);
  const [fixed,setFixed] = useState(false)

  // useSelectos redux
  const totalCount = useSelector((store) => store.properties.totalCount);
  // const totalListFilters = useSelector((store) => store.properties.totalListFilters);
  const totalCountAlquiler = useSelector((store) => store.properties.totalCountAlquiler);
  const totalCountVenta = useSelector((store) => store.properties.totalCountVenta);
  const offset = useSelector((store) => store.properties.offset);
  const filters = useSelector((store) => store.properties.filters);
  const loading = useSelector((store) => store.properties.loading);


  const location = useLocation()

  const getItems = (data) => {
    let items=[]
    for (let index = 0; index < Math.ceil(data/itemsPerPage); index++) {
      items.push(index)
    }
    return items ;
  } 

  useEffect(() => {
    setItems(getItems(totalCount))
    if(totalCount > 0){
      setFixed(true)
    }
    if(offset === 0){
      setCurrentPage(0)
    }
  },[offset,totalCount])

  useEffect(() => {
    dispatch(changeOffsetPropertiesAction(currentPage*itemsPerPage))
    if(location.pathname.includes(filters.operation.toLowerCase())){
      dispatch(getPropertiesAction())
    }
  },[currentPage])

  return (
    <>
          <div className="mt-4 pt-0 pagination d-flex align-items-center flex-column justify-content-center">
            {!fixed ? 
              <ContentLoader 
                speed={2}
                width={500}
                height={100}
                viewBox="0 0 500 100"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="58" y="2" rx="15" ry="15" width="235" height="50" /> 
                <circle cx="27" cy="27" r="22" /> 
                <circle cx="323" cy="26" r="22" />
              </ContentLoader>
              :
              <>
                <div className="d-flex align-items-center justify-content-center">
                  {/* <div onClick={() => setCurrentPage( ? items[items.length-1] : currentPage-1)} className={"cursor-pointer pagination-item d-flex justify-content-center align-items-center " + (currentPage === 0 ? 'opacity-disabled' : '')}> */}
                  <div onClick={() =>  $("html, body").animate({ scrollTop: 0 }, "slow") + (items[0] !== currentPage ? setCurrentPage(currentPage-1) : '')} className={"cursor-pointer pagination-item d-flex justify-content-center align-items-center " + (currentPage === 0 ? 'opacity-disabled' : '')}>
                      {"<"}
                  </div>
                  <div className="container-pagination d-flex align-items-center">
                      <div className={"item-paginate opacity " + (currentPage === items[0] ? '' : ' d-none ')}>
                          0-000
                      </div>
                      {items.map((item, index) => (
                        <div onClick={() =>  $("html, body").animate({ scrollTop: 0 }, "slow") + setCurrentPage(item)} className={"item-paginate " + (item === currentPage && ' active') + (currentPage === item || currentPage === item+1 || currentPage === item-1 ? '' : ' d-none ')}>
                            {item > 0 ? item*itemsPerPage + 1 : item*itemsPerPage}-{((item+1)*itemsPerPage > totalCount ? totalCount : (item+1)*itemsPerPage)}
                        </div>
                      ))}
                      <div className={"item-paginate opacity " + (currentPage === items[items.length -1] ? '' : ' d-none ') }>
                          0-000
                      </div>
                  </div>
                  <div onClick={() => $("html, body").animate({ scrollTop: 0 }, "slow") + (items[items.length-1] !== currentPage ? setCurrentPage(currentPage+1) : '')} className={"cursor-pointer pagination-item d-flex justify-content-center align-items-center " + (currentPage === items[items.length-1] ? 'opacity-disabled' : '')}>
                      {">"}
                  </div>
                </div>
                  {console.log(totalCountAlquiler)}
                  {console.log(totalCountVenta)}
                  {console.log(filters.operation)}

                <h5 className='d-block mt-lg-2 mt-1'>{loading ? 'Cargando resultados..' : <><strong> {totalCount} </strong> de <strong>{filters.operation === 'ventas' ? totalCountVenta : totalCountAlquiler}</strong>  propiedades en {filters.operation}</>}</h5>
              </>
            }

          </div>

    </>
  );
}

export default PaginatedItems
