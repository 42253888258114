import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { Link } from "react-router-dom";
import { changeLocationAction } from '../../redux/themeDucks';

function Lotes (){
    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);

    function shortLocation (full_location){
        var split = full_location.split("|")
        return split[3] + '| ' + split[1] 
    }
    const {data} = useSelector(store => store.developments.developments)
    return data ? (
        <div className={"lotes " + (data.meta.total_count > 0 ? '' : 'd-none')}>
            <div className="container-fluid">
                {/* <div className="row"> */}
                    {/* <div className="col-lg-6 d-flex align-items-stretch flex-column"> */}
                    <div className="grid-system">
                        <div className="grid_item alt-1">
                            <h2>
                                Conocé nuestros<br /> emprendimientos <br />
                                <span> destacados </span>
                            </h2>
                            <p>Estás a un click de encontrar tu próxima inversión inmobiliaria.</p>
                        </div>
                        {data.objects.map((lote,index) => {
                            if(index < 4){
                                return(
                                    <div className={'grid_item alt-'+ (index+2)} alt="" >
                                    <img src={(lote.photos).find(element => element.is_front_cover === true).original} alt="" />
                                    <div className="hover d-flex justify-content-between align-items-lg-center align-items-end">
                                        <div>
                                            <h5>{lote.address}</h5>
                                            <p>{shortLocation(lote.location.full_location)}</p>
                                        </div>
                                        <Link onClick={() => dispatch(changeLocationAction("loteo"))} to={settings.base_url + 'emprendimientos/' + lote.id} ><i className="icon-arrow-right"></i></Link>
                                    </div>
                                </div> 
                                )
                            }
                            })}
                    </div>
                    <div className="row justify-content-center">
                        <Link to={settings.base_url + "emprendimientos"} onClick={() => dispatch(changeLocationAction("emprendimientos"))} className="btn btn-effect transparent d-inline-block">VER MÁS</Link>
                    </div>
            </div>
        </div>
    ) 
    : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Lotes
