import React , {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import { getListFilters } from '../redux/propertiesDucks';
import {useSelector} from 'react-redux'

// -- Componentes -- //
import Search from '../components/propiedades/search-results'
import Main from '../components/propiedades/main'
import Grid from '../components/propiedades/grid'

function Resultados(){
    const dispatch = useDispatch();
    const {data} = useSelector(store => store.properties.listFilters)
    const settings = useSelector((store) => store.settings);

    useEffect(() => {
        const getFilters = () => {
            dispatch(getListFilters())
            window.scrollTo(0, 0);
            document.title = settings.seo.title
        }
        getFilters()
    }, [])



    return data ? (
        <div className="Resultados">
            <Search></Search>
            <Main />
            <Grid></Grid>
        </div>
    ) : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Resultados
